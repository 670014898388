.app__footer {
	display: flex;
	scroll-snap-align: center;
}
.app__footer-contact {
	margin-top: 2rem;
	display: flex;
	gap: 2.5rem;
	padding: 0 2rem;
	overflow: hidden;
	@media screen and (max-width: 900px) {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}

#message {
	margin-top: 10rem;
	margin-bottom: 10rem;
	color: var(--secondary-color);
}
.app__footer-card {
	align-items: center;
	background-color: #fef4f5;
	border-radius: 10px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 1rem 0;
	min-width: 290px;
	padding: 1rem;
	transition: all 0.3s ease-in-out;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
	&:hover {
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
	}

	a {
		color: var(--gray-color);
		text-decoration: none;
		font-size: 0.8rem;
		line-height: 1.5;
		text-align: left;
	}
	img {
		width: 50px;
		padding: 0.5rem;
		margin-right: 1rem;
		height: 50px;
	}
}
.app__footer {
	flex: 1;
	width: 100%;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
}

.app__footer-cards {
	width: 60%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-wrap: wrap;
	margin: 4em 2rem 2rem;

	.app__footer-card {
		min-width: 290px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		margin: 1rem 0;
		padding: 1rem;
		border-radius: 10px;
		cursor: pointer;
		background-color: #fef4f5;

		transition: all 0.3s ease-in-out;

		img {
			width: 40px;
			height: 40px;
			margin: 0 0.7rem;
		}

		p {
			font-weight: 500;
		}
		a {
			text-decoration: none;
			font-weight: 500;
		}
		&:hover {
			box-shadow: 0 0 25px #fef4f5;
		}

		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		width: 100%;
	}
}

.app__footer-cards .app__footer-card:last-child {
	background-color: #f2f7fb;
}

.app__footer-cards .app__footer-card:last-child:hover {
	box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
	width: 60%;
	flex-direction: column;
	margin: 1rem 2rem;
	padding: 0.5rem;

	div {
		width: 100%;

		margin: 0.75rem 0;
		border-radius: 10px;
		cursor: pointer;
		background-color: var(--primary-color);

		transition: all 0.3s ease-in-out;

		input,
		textarea {
			width: 100%;
			padding: 0.95rem;
			border: none;
			border-radius: 7px;
			background-color: var(--primary-color);

			font-family: var(--font-base);
			color: var(--secondary-color);
			outline: none;
		}

		textarea {
			height: 170px;
		}

		&:hover {
			box-shadow: 0 0 25px var(--primary-color);
		}
	}

	button {
		padding: 1rem 2rem;
		border-radius: 10px;
		border: none;
		background-color: var(--secondary-color);

		font-weight: 500;
		color: var(--white-color);
		outline: none;
		margin: 2rem 0 0 0;
		font-family: var(--font-base);

		transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
		cursor: pointer;

		&:hover {
			background-color: #2430af;
			transform: scale(1.05);
		}
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		margin: 1rem 0;
	}
}
.absolute {
	display: flex;
	width: 80vw;
	justify-content: flex-end;
	margin: 1rem;
}
.icons-socials {
	padding: 1rem;
	color: var(--secondary-color);
}

.bsIcon {
	transition: 0.4s ease-in-out;
	margin: 3rem 0 0 0;
	@media screen and (max-width: 650px) {
		margin: 1.5rem 0 0 0;
	}
}
.bsIcon:hover {
	transform: scale(1.1);
}
.app__footer-socials-icons {
	overflow: hidden;
}
.error-message {
	font-family: var(--font-base);
	color: var(--gray-color);
	text-align: center;
	background: #fff;
	background-color: #fff;
	font-weight: 700;
}
