.app__navbar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 1rem 2rem;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	border: 1px solid rgba(255, 255, 255, 0.18);
	position: fixed;

	z-index: 2;
}
.app__navbar-logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	img {
		width: 110px;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		height: 35px;
	}
}
.app__navbar-links {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;

	li {
		margin: 0 0.5rem;
		padding: 0 0.5rem;
		cursor: pointer;

		flex-direction: column;
		overflow: hidden;

		div {
			width: 5px;
			height: 5px;
			background: transparent;
			border-radius: 50%;

			margin-bottom: 5px;
		}
		a {
			color: var(--gray-color);
			text-decoration: none;
			flex-direction: column;
			text-transform: uppercase;
			font-weight: 500;
			font-size: 16px;

			&:hover {
				color: var(--secondary-color);
				transform: scale(1.1);
			}
		}
		&:hover {
			div {
				background-color: var(--secondary-color);
			}
		}
	}
	@media screen and (max-width: 900px) {
		display: none;
	}
}

.navbar-mobile {
	width: 300px;
	height: 10vh;
	display: flex;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 3;
	.background {
		width: 300px;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 5;
		padding: 1rem;
		height: 100vh;

		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		flex-direction: column;

		/* ff 3.6+ */
		background: -moz-linear-gradient(
			35deg,
			rgba(49, 59, 172, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 1) 100%
		);

		/* safari 5.1+,chrome 10+ */
		background: -webkit-linear-gradient(
			35deg,
			rgba(49, 59, 172, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 1) 100%
		);

		/* opera 11.10+ */
		background: -o-linear-gradient(
			35deg,
			rgba(49, 59, 172, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 1) 100%
		);

		/* ie 6-9 */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#313BAC', endColorstr='#FFFFFF', GradientType=1 );

		/* ie 10+ */
		background: -ms-linear-gradient(
			35deg,
			rgba(49, 59, 172, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 1) 100%
		);

		/* global 94%+ browsers support */
		background: linear-gradient(
			35deg,
			rgba(49, 59, 172, 1) 0%,
			rgba(255, 255, 255, 1) 50%,
			rgba(255, 255, 255, 1) 100%
		);
		background-size: cover;
		background-repeat: repeat;
		box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

		@media screen and (max-width: 500px) {
			width: 200px;
		}
	}
	.hamburger {
		outline: none;
		border: none;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		cursor: pointer;
		position: absolute;
		top: 18px;
		right: 15px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background: transparent;
		z-index: 10;
	}
	.ul-variants {
		display: none;
		margin-left: 5rem;
		padding: 25px;
		position: absolute;
		top: 100px;
		width: 230px;
		z-index: 6;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		.li-variants {
			margin: 0;
			padding: 0;
			list-style: none;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			cursor: pointer;
			z-index: 6;
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			a {
				color: var(--gray-color);
				text-decoration: none;
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 500;
				-webkit-touch-callout: none; /* iOS Safari */
				-webkit-user-select: none; /* Safari */
				-khtml-user-select: none; /* Konqueror HTML */
				-moz-user-select: none; /* Old versions of Firefox */
				-ms-user-select: none; /* Internet Explorer/Edge */
				user-select: none;
				-webkit-tap-highlight-color: transparent;
				&:hover {
					color: var(--secondary-color);
				}
			}
		}
		@media screen and (max-width: 500px) {
			margin-left: 3rem;
		}
		@media screen and (max-width: 350px) {
			margin-left: 0.5rem;
		}
	}

	@media screen and (min-width: 900px) {
		display: none;
	}
	@media screen and (max-width: 500px) {
		width: 200px;
	}
}
.active {
	display: block !important;
}
.active-1 {
	height: 100vh;
}
