#home {
	position: relative;
	background: url('../../assets/back.jpeg');
	background-size: cover;
	background-repeat: repeat;
	background-position: center;
	overflow: hidden;

	.app__wrapper {
		padding: 0;

		.copyright {
			display: none;
		}
	}
	@media screen and (max-width: 600px) {
		background: url('../../assets/backMobile.png');
		background-position: center;
	}
}
.app__header {
	flex: 1;
	width: 100%;
	height: 100%;
	flex-direction: row;
	scroll-snap-align: center;
	padding: 6rem 2rem 0;
	@media screen and (max-width: 1150px) {
		flex-direction: column;
		justify-content: center;
	}
	@media screen and (max-width: 400px) {
		padding: 6rem 1rem 2rem;
	}
}

.app__header-info {
	flex: 0.65;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	height: 100%;
	overflow: visible;

	@media screen and (min-width: 1200px) {
		flex: 0.9;
	}
	@media screen and (max-width: 1200px) and (min-width: 1151px) {
		flex: 0.8;
	}
	@media screen and (max-width: 1150px) {
		flex: 0.3;
	}
	@media screen and (max-width: 800px) {
		flex: 0.5;
	}
}

.app__header-badge {
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex-direction: column;
	overflow: visible;

	.badge-cmp {
		p {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;
		}
		h1 {
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;
		}
	}
	.badge-cmp,
	.tag-cmp {
		background-color: #fff;
		padding: 0.5rem 2rem;
		margin: 1rem;
		border: var(--white-color);
		border-radius: 15px;
		flex-direction: row;
		width: auto;
		overflow: hidden;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
	}
	.tag-cmp {
		flex-direction: column;
		margin-top: 3rem;
		overflow: hidden;
		p {
			width: 100%;
			text-transform: uppercase;
			text-align: right;
			-webkit-touch-callout: none; /* iOS Safari */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Old versions of Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
			user-select: none;
			overflow: hidden;
		}
	}
	span {
		font-size: 2.5rem;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}
	@media screen and (max-width: 1200px) {
		justify-content: flex-start;
		align-items: flex-start;
	}
}
.circle-cmp {
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}
}

.app__header-circles {
	flex: 0.75;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 100%;
	margin-left: 1rem;
	overflow: hidden;
	div {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin: 1rem;

		background: var(--white-color);
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

		img {
			width: 60%;
			height: 60%;
		}
	}

	// circles with different height & width

	div:nth-child(1) {
		width: 100px;
		height: 100px;
	}

	div:nth-child(2) {
		margin: 1.75rem;
		width: 150px;
		height: 150px;
	}

	div:nth-child(3) {
		width: 70px;
		height: 70px;
	}

	@media screen and (max-width: 1200px) {
		width: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 0;

		div {
			margin: 1rem;
		}
		@media screen and (max-width: 633px) {
			padding-bottom: 0;
			display: none;
		}
	}
}

.app__header-img {
	flex: 1;
	height: 100%;
	overflow: hidden;
	overflow-y: hidden;
	overflow-x: hidden;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: relative;

	img {
		width: 100%;
		object-fit: contain;
		z-index: 1;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Old versions of Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		@media screen and (max-width: 1300px) and (min-width: 1200px) {
			transform: scale(1.3);
		}
		@media screen and (max-width: 1200px) and (min-width: 1151px) {
			flex: 2;
			transform: scale(1.5);
		}
		@media screen and (max-width: 1150px) {
			transform: translateY(430px);
		}
		@media screen and (max-width: 950px) {
			transform: translateY(350px);
		}
		@media screen and (max-width: 750px) {
			transform: translateY(275px);
		}
		@media screen and (max-width: 633px) {
			transform: translateY(190px);
		}
		@media screen and (max-width: 480px) {
			transform: translateY(150px) scale(1.3);
		}
	}

	.overlay_circle {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		width: 100%;
		height: 90%;
	}

	@media screen and (max-width: 1200px) {
		margin: 2rem 0;
	}
}
