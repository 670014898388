.app__about {
	flex: 1;
	width: 100%;
	flex-direction: column;
	scroll-snap-align: center;
}

.app__about-header {
	padding: 0 1rem 0 1rem;
	@media screen and (max-width: 633px) {
		padding-top: 1rem;
		margin-top: 0;
	}
}
.app__profiles {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 2rem;
	overflow: hidden;
}
.app__profile-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
	width: 190px;
	margin: 2rem;

	img {
		width: 100%;
		height: 170px;
		border-radius: 15px;
		object-fit: cover;
	}
}
